<template>
  <el-container>
    <BaaSGovernanceConfig />
  </el-container>
</template>

<script>
import { BaaSGovernanceConfig } from '@/components/console/Index.vue';

export default {
  components: {
    BaaSGovernanceConfig,
  },
};
</script>
